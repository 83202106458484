import { SolidUser } from '@rushable/icons'
import { useNavigate } from 'react-router-dom'
import PersonName from 'components/PersonName'
import Table from 'components/Table'
import BadgeText from 'components/BadgeText'
import StageProgress from 'components/StageProgress'
import DateAndTime from 'components/DateAndTime'

import { RUSHABLE_TIMEZONE } from 'data/constant'
import { TFilterForm } from '../helpers/types'

export type TOwnershipTableProps = {
  filterForm: TFilterForm
  handleFormChange: (type: string, value: any) => void
  loading?: boolean
  total: number
  current: number
  pageSize: number
  data: any[]
  onPageNoChange: (page: number) => void
}

export default function OwnershipTable({
  filterForm,
  handleFormChange,
  loading,
  total,
  current,
  pageSize,
  data,
  onPageNoChange,
}: TOwnershipTableProps) {
  const navigate = useNavigate()
  const { sort, sort_by } = filterForm
  const columns = [
    {
      key: 'status',
      className: 'w-120',
      name: 'STATUS',
      custom: (value: string) => {
        let color: any = ''
        switch (value) {
          case 'Initiated':
            color = 'orange'
            break
          case 'Scheduled':
            color = 'yellow'
            break
          case 'Past due':
            color = 'red'
            break
          case 'Completed':
            color = 'green'
            break
        }
        return <BadgeText value={value} color={color} />
      },
    },
    {
      key: 'brand_name',
      name: 'BRAND',
      custom: (value: any) => {
        return (
          <div className='flex items-center'>
            <div className='text-ellipsis'>{value}</div>
          </div>
        )
      },
    },
    {
      key: 'location_name',
      name: 'LOCATION',
      custom: (value: any, row: any) => {
        return (
          <div className='flex items-center'>
            <div className='text-ellipsis'>{value}</div>
          </div>
        )
      },
    },
    {
      key: 'signup_form',
      name: 'NEW OWNER',
      custom: (item: any) => {
        const first_name = item?.crm_representative?.first_name || ''
        const last_name = item?.crm_representative?.last_name || ''
        return (
          <div className='flex items-center'>
            <SolidUser className='text-light-100 mr-1' size={16} />
            <div className='text-ellipsis'>{`${first_name}${
              last_name ? ` ${last_name}` : ''
            }`}</div>
          </div>
        )
      },
    },
    {
      key: 'transfer_at',
      name: 'TRANSFER DATE',
      align: 'right',
      className: 'w-35',
      sortable: true,
      sortDirection: sort_by === 'transfer_at' ? sort : '',
      onSortDirection: (key: string, item: { label: string; value: string }) => {
        const { value } = item
        handleFormChange('sort', {
          type: 'transfer_at',
          value,
        })
      },
      custom: (value: string, item: any) => {
        return (
          <div className='flex justify-end'>
            {value ? (
              <DateAndTime
                className='text-right'
                value={value}
                diffThirty
                noExpired={item?.status === 'Completed'}
                parse='YYYY-MM-DD HH:mm:ss'
                format='MMM DD, YYYY'
              />
            ) : (
              '-'
            )}
          </div>
        )
      },
    },
    {
      key: 'transfer_at',
      name: 'TRANSFER TIME',
      align: 'right',
      className: 'w-35',
      custom: (value: string, item: any) => {
        return (
          <div className='flex justify-end'>
            {value ? (
              <DateAndTime
                className='text-right'
                value={value}
                diffThirty
                noTransformTz
                noExpired={item?.status === 'Completed'}
                parse='YYYY-MM-DD HH:mm:ss'
                format='hh:mm A'
                timezone={RUSHABLE_TIMEZONE}
              />
            ) : (
              '-'
            )}
          </div>
        )
      },
    },
    {
      key: 'first_name',
      name: 'INITIATED BY',
      sortable: true,
      sortDirection: sort_by === 'last_name' ? sort : '',
      onSortDirection: (key: string, item: { label: string; value: string }) => {
        const { value } = item
        handleFormChange('sort', {
          type: 'last_name',
          value,
        })
      },
      custom: (value: string, item: any) => {
        const profilePhoto = item?.profile_photo || ''
        const first_name = item?.first_name || ''
        const last_name = item?.last_name || ''
        return (
          <div className='flex items-center'>
            <PersonName
              avatar={profilePhoto}
              name={`${first_name}${last_name ? ` ${last_name}` : ''}`}
            />
          </div>
        )
      },
    },
    {
      key: 'stage',
      name: 'STAGE',
      className: 'w-120',
      align: 'right',
      tdNoHidden: true,
      sortable: true,
      sortDirection: sort_by === 'stage' ? sort : '',
      onSortDirection: (key: string, item: { label: string; value: string }) => {
        const { value } = item
        handleFormChange('sort', {
          type: 'stage',
          value,
        })
      },
      custom: (value: any, item: any, index: number) => {
        return (
          <StageProgress
            className='ml-auto'
            popTop={index >= 8}
            onboarding={item?.account?.onboarding}
            signupForm={item?.signup_form}
            statusName={value}
          />
        )
      },
    },
  ]
  const clickedRow = (item: any) => {
    navigate(
      `/accounts/detail/${item.account_id}?from=ownership-transfer&signup_form_id=${item.signup_form_id}`,
    )
  }
  return (
    <div>
      <Table
        className='mb-20'
        loading={loading}
        columns={columns}
        data={data}
        paginationShow
        total={total}
        current={current}
        resizePageSize={pageSize}
        onPageNoChange={onPageNoChange}
        clickedRow={clickedRow}
      />
    </div>
  )
}
