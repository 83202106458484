import { useState, useContext } from 'react'
import { useSearchParams } from 'react-router-dom'
import moment from 'moment'
import { toast } from 'react-toastify'
import { SolidCopy } from '@rushable/icons'

import Label from 'components/Label'
import PersonName from 'components/PersonName'
import Button from 'components/Button'
import CopyableText from 'components/CopyableText'
import AlertText from 'components/AlertText'

import {
  initiateTransferApi,
  updateTransferApi,
  cancelTransferApi,
} from 'redux/account/ownershipTransfer'

import SignupFormContext from './context'
import { formatDigitOnly } from 'utils/digit'
import { ONBOARDING_TRANSFER_URL } from 'data/constant'
import { formatProduct } from '../../helpers'

type TOwnershipTransferProps = {
  formData: any
  isTransferEdit: boolean
  signupForm: Record<string, any> // singups api 详情
  handleIsTransferEditChange: (isEdit: boolean) => void
  handleTransferSelectSetCurrent: () => void
}

export default function OwnershipTransfer({
  formData,
  isTransferEdit,
  signupForm,
  handleIsTransferEditChange,
  handleTransferSelectSetCurrent,
}: TOwnershipTransferProps) {
  const [searchParams] = useSearchParams()
  const context = useContext(SignupFormContext)
  const { editId } = context
  const transferInfo = signupForm?.transfer
  const hasOwnershipTransfer = transferInfo?.hasOwnershipTransfer || false
  const [btnLoading, setBtnLoading] = useState({
    init: false,
    update: false,
    cancel: false,
  })
  const fromStr = searchParams.get('from')
  const formIdStr = searchParams.get('signup_form_id')

  const onInitiateTransfer = async () => {
    setBtnLoading(prev => ({ ...prev, init: true }))
    try {
      const params = {
        ...formData,
        status: signupForm.status,
        user_phone: formatDigitOnly(formData.user_phone),
        location_phone: formatDigitOnly(formData.location_phone),
        products: formatProduct(formData.products!),
        ownership_type: 'new_ownership_transfer',
      }
      await initiateTransferApi(params, editId)
      if (fromStr === 'ownership-transfer' && formIdStr) {
        window.history.replaceState(
          null,
          '',
          `${window.location.pathname}?from=${fromStr}`,
        )
      }
      // Init 成功后重新获取 signup api 数据
      context.handleInit()
      // 恢复 edit 状态
      handleIsTransferEditChange(false)
    } catch (err: any) {
      toast.error(err.message || 'Request error')
    }
    setBtnLoading(prev => ({ ...prev, init: false }))
  }
  const onUpdateTransfer = async () => {
    setBtnLoading(prev => ({ ...prev, update: true }))
    try {
      const params = {
        ...formData,
        status: signupForm.status,
        user_phone: formatDigitOnly(formData.user_phone),
        location_phone: formatDigitOnly(formData.location_phone),
        products: formatProduct(formData.products!),
        ownership_type: 'new_ownership_transfer',
      }
      await updateTransferApi(params, editId)
      // Update 成功后重新获取 signup api 数据
      context.handleInit()
      // 恢复 edit 状态
      handleIsTransferEditChange(false)
    } catch (err: any) {
      toast.error(err.message || 'Request error')
    }
    setBtnLoading(prev => ({ ...prev, update: false }))
  }
  const onCancelTransfer = async () => {
    setBtnLoading(prev => ({ ...prev, cancel: true }))
    try {
      await cancelTransferApi(editId!)
      if (fromStr === 'ownership-transfer' && formIdStr) {
        window.history.replaceState(
          null,
          '',
          `${window.location.pathname}?from=${fromStr}`,
        )
      }
      // Cancel 成功后重新获取 signup api 数据
      context.handleInit()
      // 恢复 edit 状态
      handleIsTransferEditChange(false)
    } catch (err: any) {
      toast.error(err.message || 'Request error')
    }
    setBtnLoading(prev => ({ ...prev, cancel: false }))
  }

  const renderBtns = () => {
    if (transferInfo?.status === 'completed' && transferInfo?.fromAt) {
      return (
        <AlertText
          className='w-full'
          color='green'
          text={`Ownership transfer completed on ${moment(transferInfo.fromAt).format(
            'MMM DD, YYYY @ hh:mm A',
          )}`}
        />
      )
    }
    if (transferInfo?.status === 'not_trigger') {
      return (
        <div>
          <AlertText
            className='w-full mb-6'
            color='red'
            text='Scheduled transfer did not trigger due to some error'
          />
          <CopyableText text={ONBOARDING_TRANSFER_URL + signupForm?.src}>
            <Button
              className='flex-1'
              color='secondary'
              iconAfter={<SolidCopy size={12} />}
            >
              COPY SIGNUP LINK
            </Button>
          </CopyableText>
          <Button
            className='w-full mt-6'
            color='warning'
            theme='outlined'
            loading={btnLoading.cancel}
            onClick={onCancelTransfer}
          >
            CANCEL
          </Button>
        </div>
      )
    }
    if (hasOwnershipTransfer) {
      if (isTransferEdit) {
        return (
          <Button
            className='w-full'
            color='primary'
            loading={btnLoading.update}
            onClick={onUpdateTransfer}
          >
            SAVE EDIT
          </Button>
        )
      } else {
        return (
          <div>
            <CopyableText text={ONBOARDING_TRANSFER_URL + signupForm?.src}>
              <Button
                className='flex-1'
                color='secondary'
                iconAfter={<SolidCopy size={12} />}
              >
                COPY SIGNUP LINK
              </Button>
            </CopyableText>
            <div className='mt-6 flex space-x-6'>
              <Button
                className='flex-1'
                color='warning'
                theme='outlined'
                loading={btnLoading.cancel}
                onClick={onCancelTransfer}
              >
                CANCEL
              </Button>
              {!signupForm?.current_step && (
                <Button
                  className='flex-1'
                  color='secondary'
                  theme='outlined'
                  onClick={() => handleIsTransferEditChange(true)}
                >
                  EDIT
                </Button>
              )}
            </div>
          </div>
        )
      }
    } else {
      const {
        user_email,
        user_phone,
        user_first_name,
        user_last_name,
        user_primary_language,
        // location_line_2,
        location_name,
      } = formData
      const initiateBtnDisabled =
        !user_email ||
        !user_phone ||
        !user_first_name ||
        !user_last_name ||
        !user_primary_language ||
        // !location_line_2 ||
        !location_name
      return (
        <div className='flex space-x-6'>
          <Button
            className='flex-1'
            color='warning'
            theme='outlined'
            onClick={handleTransferSelectSetCurrent}
          >
            CANCEL
          </Button>
          <Button
            className='flex-1'
            disabled={initiateBtnDisabled}
            loading={btnLoading.init}
            onClick={onInitiateTransfer}
          >
            INITIATE
          </Button>
        </div>
      )
    }
  }

  return (
    <div className='mt-6'>
      {transferInfo?.transferBy ? (
        <>
          <Label>TRANSFER INITIATED BY</Label>
          <div className='w-full mt-2 px-4 bg-dark-700'>
            <PersonName
              className='my-2'
              avatar={transferInfo?.profilePhoto}
              name={transferInfo?.transferBy}
            />
          </div>
        </>
      ) : null}
      <div className='w-full my-6 border border-dark-100 border-dashed' />
      {renderBtns()}
    </div>
  )
}
