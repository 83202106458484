import { useState, useEffect, useContext } from 'react'
import { useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'

import UnsavedPrompt from 'components/UnsavedPrompt'
import { Select } from 'components/Select'
import SignupProducts from './SignupProducts'
import SignupInfo from './SignupInfo'
import OwnershipTransfer from './OwnershipTransfer'

import { useAppSelector } from 'redux/hooks'
import { createSignsupApi, editSignsupApi } from 'redux/account'
import { useCompare } from 'hooks/useDebounce'

import SignupFormContext from './context'
import { formatDigitOnly, isNumber } from 'utils/digit'
import { TProPackage } from 'types/proPackage'
import { formatProduct } from '../../helpers'

interface Iprops {
  formDisabled: boolean
}

type TFormStatus = {
  isDirty: boolean
}

const FORMDATAINIT = {
  brand_name: '',
  brand_id: '',
  source: '',
  referrer_id: '',
  solution_engineer_id: '' as string | number,
  ae_commission: '',
  se_commission: '',
  hubspot_deal_link: '',
  user_email: '',
  user_phone: '',
  user_first_name: '',
  user_last_name: '',
  user_primary_language: '',
  location_address_query: '',
  location_line_2: '',
  location_name: '',
  location_email: '',
  location_phone: '',
  sales_tax: '',
  legal_name: '',
  business_ein: '',
  products: [] as any[],
}

function SignupForm({ formDisabled }: Iprops) {
  const [searchParams] = useSearchParams()
  const compareFunc = useCompare()
  const authMap = useAppSelector(s => s.auth.authMap)
  const profile = useAppSelector(s => s.auth.profile)
  const context = useContext(SignupFormContext)
  const { editId, signupForms } = context
  const isAffiliate =
    searchParams.get('from') === 'affiliate' ||
    !!(authMap?.roleId && authMap?.roleId >= 101 && authMap?.roleId <= 199)
  const [formInit, setFormInit] = useState(FORMDATAINIT)
  const [formData, setFormData] = useState(FORMDATAINIT)

  // 选择的 brand 已有的 Pro Package 数据
  const [brandProPackage, setBrandProPackage] = useState<TProPackage | null>(null)

  const [formStatus, setFormStatus] = useState<TFormStatus>({ isDirty: false })
  const [ownershipSelectedId, setOwnershipSelectedId] = useState<
    'new_ownership_transfer' | number
  >(0)
  const [curSignupForm, setCurSignupForm] = useState<any>(null)
  const [isTransferEdit, setIsTransferEdit] = useState(false)

  const currentSignupForm = signupForms?.currentSignupForm
  const transferredSignupForms = signupForms?.transferredSignupForms
  const transferringSignupForm = signupForms?.transferringSignupForm

  useEffect(() => {
    if (isAffiliate) {
      setFormData({ ...formData, source: 'affiliate' })
    }
  }, [isAffiliate])

  // 控制离开页面时的询问框
  useEffect(() => {
    compareFunc(formData, formInit, (flag: boolean) => {
      if (!formDisabled) {
        setFormStatus(prev => ({ ...prev, isDirty: flag }))
      }
    })
  }, [formData, formInit])

  useEffect(() => {
    if (!signupForms) {
      return
    }
    const fromStr = searchParams.get('from')
    const formIdStr = searchParams.get('signup_form_id')
    // 来自 ownership-transfer 列表
    if (fromStr === 'ownership-transfer' && formIdStr) {
      setOwnershipSelectedId(Number(formIdStr))
      return
    }
    // 有正在 transfer 的情况
    if (transferringSignupForm) {
      setOwnershipSelectedId('new_ownership_transfer')
      return
    }
    // 没有正在 transfer 的正常情况
    setOwnershipSelectedId(currentSignupForm?.id)
  }, [signupForms])

  useEffect(() => {
    if (!signupForms) {
      return
    }

    if (ownershipSelectedId === 'new_ownership_transfer') {
      if (transferringSignupForm) {
        initFormData(transferringSignupForm)
      } else {
        // 初始化 ownership transfer 数据
        // 除了不可修改信息以外，
        // 初始化时，只保留 LOCATION ADDRESS LINE 1、LOCATION ADDRESS LINE 2、LOCATION NICKNAME
        initFormData({
          ...currentSignupForm,
          crm_representative: null,
          language: '',
          location_phone: '',
          location_email: '',
          sales_tax: '',
          business_legal_name: '',
          business_ein: '',
          transfer: {
            hasOwnershipTransfer: false,
            status: '',
            transferBy: profile?.userName || '',
            profilePhoto: profile?.userPhoto || '',
            timezone: currentSignupForm?.location_address?.timezone || '',
            transferLocationName: currentSignupForm?.transferLocationName || '',
            doneAt: '',
          },
        })
        setIsTransferEdit(true)
      }
    } else if (isNumber(ownershipSelectedId)) {
      if (ownershipSelectedId === currentSignupForm?.id) {
        initFormData(currentSignupForm)
        setIsTransferEdit(false)
      } else {
        const transferredForm = transferredSignupForms?.find(
          (v: any) => v.id === ownershipSelectedId,
        )
        !!transferredForm && initFormData(transferredForm)
      }
    }
  }, [ownershipSelectedId, signupForms])

  const initFormData = (signupForm: any) => {
    const {
      brand,
      // signup_form_cuisine_types,
      crm_representative,
      location_address,
      signup_products,
      transfer,
    } = signupForm || {}

    const { email, phone, first_name, last_name } = crm_representative || {}

    const { line_1, line_2, city, state, zipcode } = location_address || {}
    const loc_add_query = `${line_1 || ''} ${city || ''} ${state || ''} ${zipcode || ''}`

    const products = signup_products?.map((item: any) => ({
      subAmount: item.subscription_amount,
      subDiscount: item.subscription_discount,
      dispatchFee: item.dispatch_fee,
      setupFee: item.signup_fee,
      setupFeeDiscount: item.signup_fee_discount,
      isFreeWebsite: item.mention_free_website,
      includeProPackage: item.pro_package ? 1 : 0,
      proPackagePrice: item.pro_package?.unit_amount,
      proPackageTrialMonths: +item?.pro_package_coupon?.duration || 0,
      // pro_package_amount = proPackagePrice - proPackageCoupon (后段逻辑)
      proPackageAmount: item.pro_package_amount,
    }))
    // if brand exists, and brand has pro package
    if (brand?.pro_package) {
      const { unit_amount } = brand.pro_package
      setBrandProPackage({ unit_amount: Number(unit_amount) })
    }

    const obj = {
      brand_name: signupForm?.brand_name,
      brand_id: signupForm?.brand_id,
      source: signupForm?.source,
      referrer_id: signupForm?.referrer_id,
      solution_engineer_id: signupForm?.solution_engineer_id,
      ae_commission: signupForm?.ae_commission,
      se_commission: signupForm?.se_commission,
      hubspot_deal_link: signupForm?.hubspot_deal_link,
      user_email: email || '',
      user_phone: phone || '',
      user_first_name: first_name || '',
      user_last_name: last_name || '',
      user_primary_language: signupForm?.language,
      location_address_query: loc_add_query,
      location_line_2: line_2 || '',
      location_name: transfer?.transferLocationName || signupForm?.location_name,
      location_email: signupForm?.location_email,
      location_phone: signupForm?.location_phone,
      sales_tax: isNumber(signupForm?.sales_tax)
        ? Number(signupForm?.sales_tax)?.toFixed(3)
        : '',
      legal_name: signupForm?.business_legal_name,
      business_ein: signupForm?.business_ein,
      products, // 需做格式化处理
    }
    setFormInit(obj)
    setFormData(cloneDeep(obj))
    setCurSignupForm(signupForm)
  }

  const onChangeForm = (key: string, value: any) => {
    setFormData({ ...formData, [key]: value })
  }

  const handleSubmit = (status: 'signup' | 'draft') => {
    const params = {
      ...formData,
      status,
      user_phone: formatDigitOnly(formData.user_phone),
      location_phone: formatDigitOnly(formData.location_phone),
      products: formatProduct(formData.products!),
    }
    setFormStatus(prev => ({ ...prev, isDirty: false }))
    const api = editId ? editSignsupApi : createSignsupApi
    return new Promise((resolve, reject) => {
      api(params, editId)
        .then(res => {
          toast.success(res.message)
          resolve(res)
        })
        .catch(err => {
          toast.error(err.message || 'Request error')
          reject(err)
        })
    })
  }

  /**
   * Update form data: brand_id, brand_name
   * Update brand pro package info
   *
   * @param label
   * @param item
   */
  const onBrandChange = (label: any, item: any) => {
    setFormData({
      ...formData,
      brand_id: item.id,
      brand_name: label,
      products: [], // 换 brand 则重新选 products, 避免已选 products 和 切换的 brand 的 pro package 有冲突
    })
    setBrandProPackage(
      item.pro_package ? { unit_amount: Number(item.pro_package.unit_amount) } : null,
    )
  }

  const handleTransferSelectSetCurrent = () => {
    setOwnershipSelectedId(currentSignupForm?.id)
    setFormData(cloneDeep(formInit))
  }

  const renderOwnershipOptions = () => {
    const defaultOwnershipOptions = [
      {
        label: 'Current ownership',
        value: currentSignupForm?.id,
      },
      {
        label: 'New ownership transfer',
        value: 'new_ownership_transfer',
        optionChildren: <div className='pl-4 text-yellow'>New ownership transfer</div>,
      },
    ]
    if (signupForms) {
      if (!transferringSignupForm && !transferredSignupForms) {
        return defaultOwnershipOptions
      } else if (transferredSignupForms?.length > 0) {
        const transferredOptions = transferredSignupForms
          .sort((a: any, b: any) => {
            // 按时间排序，距今最近的靠前
            return moment(b.transfer.fromAt).diff(moment(a.transfer.fromAt))
          })
          .map((form: any) => {
            const id = form?.id
            const fromAt = form?.transfer?.fromAt || ''
            const numberOfOwner = form?.transfer?.numberOfOwner || ''
            return {
              label: numberOfOwner
                ? `Ex-ownership (transferred on ${moment(fromAt).format('M/D/YYYY')})`
                : 'Ex-ownership',
              value: id,
            }
          })
        defaultOwnershipOptions.splice(1, 0, ...transferredOptions)
        return defaultOwnershipOptions
      }
    }
    return defaultOwnershipOptions
  }

  const transferDisabled =
    ownershipSelectedId === 'new_ownership_transfer' && !transferringSignupForm?.transfer

  // numberOfOwner === 0 时，SignupForm 数据是第一任所有者，不需要显示 transfer 相关信息
  const OwnershipTransferShow =
    ownershipSelectedId === 'new_ownership_transfer' ||
    (curSignupForm?.transfer && curSignupForm?.transfer?.numberOfOwner !== 0)
  return (
    <div>
      {curSignupForm?.status === 'live' && !isAffiliate ? (
        <Select
          className='mb-10'
          disabled={transferDisabled}
          placeholder='Select'
          value={ownershipSelectedId}
          options={renderOwnershipOptions()}
          onChange={value => {
            setIsTransferEdit(false)
            setOwnershipSelectedId(value)
          }}
        />
      ) : null}
      <div className='flex'>
        <SignupInfo
          brandProPackage={brandProPackage}
          formData={formData}
          signupForm={curSignupForm}
          formDisabled={formDisabled}
          isAffiliate={isAffiliate}
          isTransferEdit={isTransferEdit}
          setFormData={setFormData}
          onBrandChange={onBrandChange}
          onChangeForm={onChangeForm}
        />
        <div className='flex-1'>
          <SignupProducts
            brandProPackage={brandProPackage}
            formDisabled={formDisabled}
            products={formData.products}
            signupForm={curSignupForm}
            handleProductsChange={info => onChangeForm('products', info)}
            handleSubmit={handleSubmit}
          />
          {OwnershipTransferShow ? (
            <OwnershipTransfer
              formData={formData}
              signupForm={curSignupForm}
              isTransferEdit={isTransferEdit}
              handleIsTransferEditChange={(isEdit: boolean) => setIsTransferEdit(isEdit)}
              handleTransferSelectSetCurrent={handleTransferSelectSetCurrent}
            />
          ) : null}
        </div>
        <UnsavedPrompt when={formStatus.isDirty} />
      </div>
    </div>
  )
}

export default SignupForm
